<template>
    <div class="enjoy row justify-content-center">

        <!-- [Left] fixd logo  -->
        <transition name="fade">
            <div v-show="$route.name!='index' && delay" class="page_kv_fixed">
                <img src="@/assets/images/img_kv_head_page.png" class="w-100" alt="">
            </div>
        </transition>

        <!-- Filter -->
        <div class="subNav col-xl-10 mb-3 mb-md-3 mt-md-1" v-show="!isPagesLoading" ref="clickaway">
            <!-- select 1 -->
            <!-- <ul class="list-unstyled text-white mb-0 mr-2">
                <li @click="activeFilter == 'F1' ? showFilter('') : showFilter('F1')">
                    <button class="btn btn-danger dropdown font-weight-bold text-left text-white" :class="(activeFilter == 'F1' ? 'dropdown--active' : '')">
                        <span>路名</span>
                        <svg class="arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                        <polygon points="11.63 5.19 8.01 8.44 4.37 5.18 3.2 6.51 8.01 10.81 12.8 6.51 11.63 5.19"/>
                        </svg>
                    </button>
                </li>

                <transition name="fade">
                    <li v-if="activeFilter == 'F1'" :class="(activeFilter == 'F1' ? 'active' : '')" class="dropdown__item">
                        <ul class="list-unstyled">
                            <li>路名</li>
                            <li>景點</li>
                        </ul>
                    </li>
                </transition>
            </ul> -->

            <!-- select 2 -->
            <ul class="list-unstyled text-white mb-0">
                <li @click="activeFilter == 'F2' ? showFilter('') : showFilter('F2')">
                    <button class="btn btn-danger dropdown font-weight-bold text-left text-white" :class="(activeFilter == 'F2' ? 'dropdown--active' : '')">
                        <span>{{defaultItem}}</span>
                        <svg class="arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                        <polygon points="11.63 5.19 8.01 8.44 4.37 5.18 3.2 6.51 8.01 10.81 12.8 6.51 11.63 5.19"/>
                        </svg>
                    </button>
                </li>
                
                <transition name="toggle">
                    <li v-if="activeFilter == 'F2'" :class="(activeFilter == 'F2' ? 'active' : '')" class="dropdown__item">    
                        <ul class="list-unstyled" @click="activeFilter == 'F2' ? showFilter('') : showFilter('F2')">
                            <li v-if="defaultItem!='選擇路名'" @click="defaultItem ='選擇路名'; ReadData(); showToggle = false">
                                全部
                            </li>
                            <li v-for="(item, index) in EnjoyListStreet" :key="index" @click="defaultItem = item;RD(index);">{{item}}</li>
                        </ul>
                    </li>
                </transition>
            </ul>
        </div>       

        <!-- card -->
        <div class="col-xl-10" v-for="(item, index) in EnjoyListMAX" :key="item.ID" data-aos="fade-up" data-aos-duration="1500" data-aos-anchor-placement="top-bottom">
            <div class="card mb-4">
                <div class="row">
                    <!-- photo -->
                    <div class="col-lg-5 mb-4 mb-lg-0" v-if="UploadStatus(item.Imgs)">
                        <carousel v-if="item.Imgs.length==1">
                            <slide v-for="photo in item.Imgs" :key="photo.ID">
                                <img :src="photo.Img">
                            </slide>
                        </carousel>
                        <carousel v-else>
                            <slide v-for="photo in item.Imgs" :key="photo.ID">
                                <img :src="photo.Img">
                            </slide>
                            <template #addons>
                                <navigation />
                                <pagination class="mb-0 pl-0"/>
                            </template>
                        </carousel>
                    </div>
                    <div class="col-lg-5 mb-4 mb-lg-0" v-else>
                        <carousel>
                            <slide>
                                <img src="https://via.placeholder.com/400x300">
                            </slide>
                        </carousel>
                    </div>
                    <!-- description -->
                    <div class="col-lg-7 d-flex flex-column">
                        <div class="des__head">
                            <div class="d-flex justify-content-between">
                                <h2 class="h4 font-weight-bold mb-1">{{item.Title}}</h2>
                                <small>{{index+1}}/{{Enjoylength}}</small>
                            </div>
                        </div>
                        <div data-nosnippet class="des__body pb-3">
                            <p class="mb-0">{{item.Des}}</p>
                            <!-- <span class="more position-relative pt-3" :class="{'more--active': item.readMore}">
                                <a v-if="!item.readMore" @click="item.readMore = !item.readMore; DesClick(index)" class="btn w-100">閱讀更多</a>
                                <a v-else @click="item.readMore = !item.readMore" class="btn w-100">收合</a>               
                            </span> -->
                        </div>
                        <div class="des__foot d-flex flex-column flex-md-row align-items-md-end">
                            <ul class="flex-grow-1 list-unstyled h6 font-weight-normal mb-0">
                                <li>地址：{{item.Address}}</li>
                                <li>電話：{{item.Phone}}</li>
                            </ul>
                        </div>            
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-10">
            <div class="row">
                <div class="col-md-6 col-lg-4" v-for="(item, index) in EnjoyListMIN" :key="item.ID"  data-aos="fade-up" data-aos-duration="1500" data-aos-anchor-placement="top-bottom">
                    <div class="card mb-4">
                        <!-- photo -->
                        <div class="mb-3" v-if="UploadStatus(item.Imgs)">
                            <carousel v-if="item.Imgs.length==1">
                                <slide v-for="photo in item.Imgs" :key="photo.ID">
                                    <img :src="photo.Img">
                                </slide>
                            </carousel>
                            <carousel v-else>
                                <slide v-for="photo in item.Imgs" :key="photo.ID">
                                    <img :src="photo.Img">
                                </slide>
                                <template #addons>
                                    <navigation />
                                    <pagination class="mb-0 pl-0"/>
                                </template>
                            </carousel>
                        </div>
                        <div class="mb-3" v-else>
                            <carousel>
                                <slide>
                                    <img src="https://via.placeholder.com/400x300">
                                </slide>
                            </carousel>
                        </div>
                        <!-- description -->
                        <div class="d-flex flex-grow-1 flex-column">                            
                            <div data-nosnippet class="des__body" :class="{'des__body--active': item.readMore}">
                                <div class="des__head">
                                    <div class="d-flex justify-content-between">
                                        <h2 class="h5 font-weight-bold mb-2">{{item.Title}}</h2>
                                        <small>{{index+1}}/{{Enjoylength}}</small>
                                    </div>
                                </div>
                                <p class="mb-0" v-html="item.Des"></p>
                            
                                <div class="des__foot d-flex flex-column flex-md-row align-items-md-end" :class="{'des__foot--active': item.readMore}">
                                    <ul class="flex-grow-1 list-unstyled h6 font-weight-normal mb-3">
                                        <li>地址：{{item.Address}}</li>
                                        <li>電話：{{item.Phone}}</li>
                                    </ul>
                                </div>
                            </div>
                            <span class="more position-relative pt-1" :class="{'more--active': item.readMore}">
                                <a v-if="!item.readMore" @click="item.readMore = !item.readMore; DesClick(index)" class="btn w-100">閱讀更多</a>
                                <a v-else @click="item.readMore = !item.readMore" class="btn w-100">收合</a>               
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- loading -->
        <PagesLoading v-show="isPagesLoading"/>
    </div>
</template>

<script>
// import $ from 'jquery';
import PagesLoading from '@/components/PagesLoading.vue'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';

var ELMAX = [], ELMIN = [];

export default {
    ELMAX, ELMIN,
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation,
        PagesLoading
    },
    data() {
        return {
            delay:false,
            defaultItem: '選擇路名',
            isPagesLoading: true,
            activeFilter: '',
            EnjoyListMAX: null,
            EnjoylengthMAX: null,
            EnjoyListMIN: null,
            EnjoylengthMIN: null,
            EnjoyListStreet: [],
            readMore:false,
        }
    },
    /* Init */
    created() {
        this.ReadData();
        this.metaData();
    },
    mounted() {
        window.addEventListener('click', this.handleClickaway);
    },
    beforeUnmount() {
        window.removeEventListener('click', this.handleClickaway);
    },
    updated(){
    },

    watch: {//watch: {'$route' (to, from) {console.log(to, from);}} //就是可以hook的地方
        '$route'(){
            this.ReadData();
            this.metaData();
            this.defaultItem='選擇路名';
        }
    },
    methods: {
        showFilter: function(enjoyClass){
            this.activeFilter = enjoyClass;
        },

        RD:function(){
            if(this.defaultItem!='選擇路名'){
                this.EnjoyListMAX=ELMAX.filter(item => item.Street==this.defaultItem);//大卡
                this.EnjoyListMIN=ELMIN.filter(item => item.Street==this.defaultItem);//小卡
                this.Enjoylength=this.EnjoyListMIN.length+this.EnjoyListMAX.length;//大加小卡上架數
            }            
        },

        metaData:function(){
            let PATH = this.$route.path.split("/")[1];
            if(PATH == 'eat'){
                document.title = "2021淡水八景4.0-美食不落空";
                document.querySelector('meta[property="og:title"]').setAttribute("content", '2021淡水八景4.0-美食不落空');
                document.querySelector('meta[name="description"]').setAttribute("content", '便當、飯店吃膩了嗎？趁著假日來淡水，立馬親嚐全台盛名的阿給、魚丸、傳統餅！來淡水一網打盡，多種口感與各式美食，肚子心情都不落空。');
                document.querySelector('meta[property="og:description"]').setAttribute("content", '便當、飯店吃膩了嗎？趁著假日來淡水，立馬親嚐全台盛名的阿給、魚丸、傳統餅！來淡水一網打盡，多種口感與各式美食，肚子心情都不落空。');
            } else if (PATH == 'play'){
                document.title = "2021淡水八景4.0-就該這麼玩";
                document.querySelector('meta[property="og:title"]').setAttribute("content", '2021淡水八景4.0-就該這麼玩');
                document.querySelector('meta[name="description"]').setAttribute("content", '週末假日拋開繁忙的步調，放慢你的步調，走進淡水體驗各種異國風情、港灣、古蹟建築，淡水魅力值得細細品味。');
                document.querySelector('meta[property="og:description"]').setAttribute("content", '週末假日拋開繁忙的步調，放慢你的步調，走進淡水體驗各種異國風情、港灣、古蹟建築，淡水魅力值得細細品味。');
            } else if (PATH == 'stay'){
                document.title = "2021淡水八景4.0-開心住美景";
                document.querySelector('meta[property="og:title"]').setAttribute("content", '2021淡水八景4.0-開心住美景');
                document.querySelector('meta[name="description"]').setAttribute("content", '來淡水就該住的該開心、才能玩得盡興。住就該搭配百萬美景，看潮起潮落，賞山海連天。跟個我們一起探索淡水美景飯店吧。');
                document.querySelector('meta[property="og:description"]').setAttribute("content", '來淡水就該住的該開心、才能玩得盡興。住就該搭配百萬美景，看潮起潮落，賞山海連天。跟個我們一起探索淡水美景飯店吧。');
            } else if (PATH == 'buy'){
                document.title = "2021淡水八景4.0-歐咪阿給報你知";
                document.querySelector('meta[property="og:title"]').setAttribute("content", '2021淡水八景4.0-歐咪阿給報你知');
                document.querySelector('meta[name="description"]').setAttribute("content", '旅行的美好就該與大家分享，來淡水別忘了帶份名產、伴手禮給親愛的家人、朋友和同學喔，高人氣淡水伴手禮，我們幫你一網打盡。');
                document.querySelector('meta[property="og:description"]').setAttribute("content", '旅行的美好就該與大家分享，來淡水別忘了帶份名產、伴手禮給親愛的家人、朋友和同學喔，高人氣淡水伴手禮，我們幫你一網打盡。');
            }
        },

        ReadData:function(){
            //console.log(this.$route.path.split("/")[1]);
            this.isPagesLoading = true;
            this.EnjoyListMAX=null;
            this.EnjoyListMIN=null;
            this.Enjoylength=0;

            var FD = new FormData();
            FD.append('mode', 'sct');
            // FD.append('data', '{"class":"Enjoy","lang":"zh-TW"}');
            FD.append('data', '{"class":"Enjoy","lang":"zh-TW","Imgs":true,"sw":"1"}');

            this.axios({
                method: 'post',
                url: 'RApi',
                data: FD,
                headers: {'Content-Type': 'multipart/form-data' }
            })
            .then(response => {
                var Res=response.data.data;
                
                //大卡片
                ELMAX = Res.filter(item => (item.EnjoyClass.split(",").indexOf(this.$route.path.split("/")[1])!=-1) && (item.CardSw=='1' || item.CardSw=='on'));
                this.EnjoyListMAX=ELMAX;
                //小卡片
                ELMIN = Res.filter(item => (item.EnjoyClass.split(",").indexOf(this.$route.path.split("/")[1])!=-1) && (item.CardSw=='0' || item.CardSw=='off' || item.CardSw=='null' || item.CardSw=='' || item.CardSw==undefined)).sort(function(){return 0.5 - Math.random()});
                this.EnjoyListMIN=ELMIN; 
                //大加小卡上架數
                this.Enjoylength=this.EnjoyListMIN.length+this.EnjoyListMAX.length;

                //路名篩選
                //找class符合path的data 
                var Street=Res.filter(item => (item.EnjoyClass.split(",").indexOf(this.$route.path.split("/")[1])!=-1)); 
                // console.log(Street);
    
                var StreetArr=[];                
                Street.forEach(function(item){StreetArr.push(item.Street);});
                this.EnjoyListStreet=StreetArr.filter((el,i,arr) => arr.indexOf(el)===i).sort(function compareFunction(C1,C2){
                    return C1.localeCompare(C2);
                });
                //載入狀態定義
                this.isPagesLoading = false;
                
                this.delay=true;
            })
            .catch(function (error) {
                console.log(error);
            }); 
        },

        UploadStatus: function(response=null){
            if(response!=null){
                return response;
            } else {
                return false;
            }
        },

        handleClickaway(e) {
			const { clickaway } = this.$refs;
			if(!clickaway.contains(e.target)){
				this.activeFilter = '';
			}
        },
    }
}
</script>